/* eslint-disable */
require('core-js/features/array/from')
global.Promise = require('bluebird/js/browser/bluebird.core')

// Some browsers (mainly IE) does not have this property, so we need to build it manually...
if (!window.location.origin) {
  window.location.origin = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`
}

global.Promise.config({
  longStackTraces: true,
  warnings: true,
})

if (navigator.userAgent.indexOf('Edge') > -1) {
  // deleting fetch as of Edge 14 errors
  // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/7528873/
  // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8653298/
  delete window.fetch
}

if (!('fetch' in window)) {
  require('whatwg-fetch')
}

if (!('URLSearchParams' in window)) {
  window.URLSearchParams = require('url-search-params')
}

if (process.env.NODE_ENV === 'production') {
  if (typeof Object.assign !== 'function') {
    Object.assign = function (target, varArgs) {
      // .length of function is 2

      if (target == null) {
        // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object')
      }

      const to = Object(target)

      for (let index = 1; index < arguments.length; index++) {
        const nextSource = arguments[index]

        if (nextSource != null) {
          // Skip over if undefined or null
          for (const nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey]
            }
          }
        }
      }
      return to
    }
  }

  if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (searchString, position) {
      const subjectString = this.toString()
      if (
        typeof position !== 'number' ||
        !isFinite(position) ||
        Math.floor(position) !== position ||
        position > subjectString.length
      ) {
        position = subjectString.length
      }
      position -= searchString.length
      const lastIndex = subjectString.lastIndexOf(searchString, position)
      return lastIndex !== -1 && lastIndex === position
    }
  }

  // WE ARE NOT USING THIS POLYFILL, IT BREAKS IE11 WITH MOBX
  // Mobx uses or extends Array
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
  // if (!Array.prototype.find) {
  //   Object.defineProperty(Array.prototype, 'find', {
  //     value: function(predicate) {
  //       if (this == null) {
  //         throw new TypeError('"this" is null or not defined')
  //       }
  //       var o = Object(this)
  //       var len = o.length >>> 0
  //       if (typeof predicate !== 'function') {
  //         throw new TypeError('predicate must be a function')
  //       }
  //       var thisArg = arguments[1]
  //       var k = 0
  //       while (k < len) {
  //         var kValue = o[k]
  //         if (predicate.call(thisArg, kValue, k, o)) {
  //           return kValue
  //         }
  //         k++
  //       }
  //       return undefined
  //     },
  //   })
  // }
}
